import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import CardList from "../components/common/CardList"
import bannerImg from "../assets/images/inspirations-banner.jpg"
import Banner from "../components/common/Banner"
import { SEO } from "../components/seo"

export const pageQuery = graphql`
  query InspirationsQuery {
    inspirations: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "inspiration-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            template
            title
            description
            bannerImage {
              publicURL
            }
          }
        }
      }
    }
  }
`

const Inspirations = ({ data }) => {
  const inspirations = [];
  data.inspirations.edges.forEach(edge => {
    inspirations.push({
      slug: edge.node.frontmatter.slug,
      title: edge.node.frontmatter.title,
      description: edge.node.frontmatter.description,
      image: edge.node.frontmatter.bannerImage.publicURL
    })
  });
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Inspirations', link: '' }
  ]
  return (
    <Layout>
      <Banner
        data="Inpirations"
        image={bannerImg}
        crumbs={crumbs}
      />
      <CardList data={inspirations} />
    </Layout>
  )
};

export const Head = () => (
  <SEO
    title="Inspirations - Housefull Events & Hospitality Management Services"
    description="Discover the inspirations for visually stunning family functions, corporate functions, motivational classes, games, shows and Travel Destinations here."
  />
)

export default Inspirations
